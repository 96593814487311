import { useState } from "react";
import { Table } from "react-daisyui";
import { Header } from "../components/Header";
import { useMoralisCloudFunction } from "react-moralis";
import { MoonLoader } from "react-spinners";

export default function Invitation() {

  const { data: rankings } = useMoralisCloudFunction(
    "fetchTopReferRankings",
    null,
    { autoFetch: true }
  );

  const [showConnectModal, setShowConnectModal] = useState(false);

  return (
    <>
      <Header setShowConnectModal={setShowConnectModal} showConnectModal={showConnectModal} category="refer" />
      <div className="min-h-screen">
        <div className="w-full p-4">
        {!rankings && (
            <div className="w-full flex justify-center items-center">
              <MoonLoader color="#570df8" size={100} />
            </div>
          )}
          {rankings && (
            <div className="w-full relative z-1 bg-gray-100 rounded shadow-lg p-0 md:p-4 text-center overflow-x-scroll">
            <Table className="rounded-box w-full">
              <Table.Head>
                <span>Rank</span>
                <span>Total Active Invitations</span>
                <span>User</span>
              </Table.Head>

              <Table.Body>
                {rankings.map((rank, index) => {
                  return (
                    <Table.Row key={rank.wallet_id}>
                      <div>
                        {index + 1}
                      </div>
                      <div>{rank.lv1_member_count}</div>
                      <div>{rank.wallet_id}</div>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
          )}
          
        </div>
      </div>
    </>
  );
}
