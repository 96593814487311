import React from "react";
// import { useMoralis } from "react-moralis";
// import {Modal, Dropdown, Menu, Navbar} from 'react-daisyui'
import { Menu, Navbar } from "react-daisyui";
import { Link } from "react-router-dom";
import c from "classnames";

export function Header(props) {
  const { category } = props;
  // const { authenticate, isAuthenticated, logout } = useMoralis();
  // const {showConnectModal, setShowConnectModal, category} = props

  // const toggleModal = () => {
  //   setShowConnectModal(!showConnectModal)
  // }

  // const login = () => {
  //   toggleModal()
  //   try {
  //     authenticate({
  //       signingMessage: "Log in",
  //     })
  //   } catch (error) {
  //     alert(error)
  //   }

  // }

  // const loginMobile = () => {
  //   toggleModal()
  //   authenticate({
  //     signingMessage: "Log in",
  //     provider: "walletconnect",
  //     mobileLinks: [
  //       "metamask",
  //     ]
  //   })
  // }

  return (
    <>
      <Navbar>
        <Navbar.Start>
          <Link
            to={"/"}
            className="btn btn-ghost normal-case text-xl h-20 hidden sm:block"
          >
            <img className="max-h-full" src="/assets/logo.png" alt="logo" />
          </Link>
        </Navbar.Start>
        <Navbar.Center className="flex">
          <Menu horizontal className="p-0">
            <Menu.Item>
              <Link className={c({ active: category === "nft" })} to={"/"}>
                NFT
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link
                className={c({ active: category === "refer" })}
                to={"/invites"}
              >
                Invitations
              </Link>
            </Menu.Item>
          </Menu>
        </Navbar.Center>
        <Navbar.End></Navbar.End>
      </Navbar>
    </>
  );
}
